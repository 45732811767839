import { useEffect, useContext } from "react"
import { AuthContext } from "../contexts/AuthContext"

function HomePage() {

  const { user, organizations, currentOrganization } = useContext(AuthContext)

  return (
      <main className="ua-main__landings flex-grow-1 d-flex flex-column h-100 overflow-hidden">
        <header className="ua-main__header px-3 px-md-5 pt-5 pb-4 d-flex flex-row justify-content-between align-items-center">
          <h1 className="m-0">Dashboard</h1>
          
        </header>  
        <div className="ua-main__content px-3 px-md-5 flex-grow-1 pb-3 overflow-auto d-flex">
        { currentOrganization && <iframe width="100%" height="auto" src={currentOrganization.data().dashboard} frameborder="0" style={{border:0}}></iframe>

        }
        </div>
      </main>
  );
}

export default HomePage;
