import {signInWithGoogle } from "../services/firebase";
import { useContext, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext"
import {Google} from 'react-bootstrap-icons';

const logoUrl = "https://firebasestorage.googleapis.com/v0/b/galapagos-waddle.appspot.com/o/assets%2Funify_logo.png?alt=media&token=550c0953-7ef7-4656-bff0-adff998c1b6e"





function LoginPage() {
  var { user, loading } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (user) return navigate("/");
   
  }, [user, loading]);

  return (
      <main className="d-flex flex-row justify-content-center align-items-center h-100">
        <div>
           <div className="login-logo-wrapper">
              <h2 className="login-logo-name d-flex flex-row justify-content-center"><img className="login-logo-image" src={logoUrl}/><span>Unichats</span></h2>
            </div>
           <button className="login__btn login__google btn btn-dark btn--ua-backoffice" onClick={signInWithGoogle}>
            <Google className="me-3"/> Login with Google
          </button>
        </div>
      </main>
  );
}

export default LoginPage;