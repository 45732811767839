import {  useContext } from "react"
import { AuthContext } from "../contexts/AuthContext"
import { storage } from '../services/firebase'
import { useNavigate} from "react-router-dom";
import {
    ref,
    uploadBytesResumable,
    getDownloadURL 
} from "firebase/storage";
import { ChatSquare, Pencil, Trash } from 'react-bootstrap-icons';

const Interaction = (props) => {


  const onTypeChange = (e) => {
    props.handleChange({...props.interaction, type: e.target.value, content: {text: ""}})
  }
  const onContentChange = (newContent) => {
    props.handleChange({...props.interaction, content: newContent})
  }

  const onChange = (key, e) => {
    props.handleChange({...props.interaction, [key]: e.target.value})
  }

  return (
    <div className="card card-interaction">
    <div className="card-header d-flex flex-row align-items-center justify-content-start bg-white">
      <span className="badge text-bg-primary me-3">{props.interaction.order}</span>
      <select className="interaction-type" value={props.interaction.type} onChange={onTypeChange}>
        <option value="text">Text</option>
        <option value="text_with_link">Link</option>
        <option value="text_with_options">Options</option>
        <option value="text_with_input">Input</option>
      </select>
      <div className="flex-grow-1"></div>
      <select className="ms-3" value={props.interaction.next} onChange={(e) => onChange('next', e)} >
            <option value="end"><bold>Next:</bold> End</option>
            {props.nextOptions.filter(el => el.order == props.interaction.order? false: true).map(el => {return <option value={el.id}>Interaction #{el.order}</option>})}
        </select>
      <button type="button" className="btn btn-light justify-self-end" onClick={() => props.deleteHandle(props.interaction)}><Trash/></button>
    </div>
    <div class="card-body">
    
      
      
      {(() => {
        switch(props.interaction.type) {
        case "text":
          return <TextInteraction nextOptions={props.nextOptions} order={props.interaction.order}  interactionKey={props.interactionKey} handleChange={onContentChange} content={props.interaction.content}/>
          break;
        case "text_with_link":
          return <LinkInteraction nextOptions={props.nextOptions} order={props.interaction.order}  interactionKey={props.interactionKey} handleChange={onContentChange} content={props.interaction.content}/>
          break;
        case "text_with_options":
          return <OptionsInteraction nextOptions={props.nextOptions} order={props.interaction.order} interactionKey={props.interactionKey} handleChange={onContentChange} content={props.interaction.content}/>
          break;
        case "text_with_input":
          return <TextInputInteraction nextOptions={props.nextOptions} order={props.interaction.order} interactionKey={props.interactionKey} handleChange={onContentChange} content={props.interaction.content}/>
          break;
        }
      })()}
      
      </div>
      { props.isForm && (props.interaction.type == "text_with_options" || props.interaction.type == "text_with_input") && (
        <section className="card-footer bg-white">
          <label>Form Field Name: </label>
          <input className="form-control" value={props.interaction.formAttributeName} onChange={(e) => onChange("formAttributeName", e)}/>
        </section>)}
    </div>
  )
};

const TextInteraction = (props) => {

  const onChange = (key, e) => {
    props.handleChange({...props.content, [key]: e.target.value})
  }

  return (
    <div>
      <textarea className="form-control" name={`interaction.${props.interactionKey}.content.text`} onChange={(e) => {onChange("text", e)}} placeholder="Chat content text" value={props.content.text}></textarea>
    </div>
  )
}

const TextInputInteraction = (props) => {

  const onChange = (key, e) => {
    props.handleChange({...props.content, [key]: e.target.value})
  }

  return (
    <div>
      <textarea className="form-control mb-2" name={`interaction.${props.interactionKey}.content.text`} onChange={(e) => {onChange("text", e)}} placeholder="Chat content text" value={props.content.text}></textarea>
      <input className="form-control mb-2" name={`interaction.${props.interactionKey}.content.placeholder`} onChange={(e) => {onChange("placeholder", e)}} placeholder="Chat content placeholder" value={props.content.placeholder}></input>
      <input className="form-control mb-2" name={`interaction.${props.interactionKey}.content.button_cta`} onChange={(e) => {onChange("button_cta", e)}} placeholder="Chat content button CTA" value={props.content.button_cta}></input>
    </div>
  )
}


const LinkInteraction = (props) => {
  const {organizations, currentOrganization } = useContext(AuthContext)
  const onChange = (key, e) => {
    props.handleChange({...props.content, [key]: e.target.value})
  }

  const handleImageChange = async (e) => {
    const url = await uploadImage(e.target.files[0], currentOrganization.storageId)
    onChange("thumbnail", {target:{value:url}})
  }

  async function  uploadImage(image, path) {
      try {
        
        const fileRef = ref( storage, `/organizations/${path}/` + image.name);
        const uploadTask = await uploadBytesResumable(fileRef, image);

        const downloadURL = await getDownloadURL(uploadTask.ref)

        return downloadURL

      } catch (error) {
        console.log("ERR ===", error);
        alert("Image uploading failed!");
      }
    }

  return (
    <div>
      <input className="form-control mb-2" name={`interaction.${props.interactionKey}.content.text`} onChange={(e) => {onChange("text", e)}} placeholder="Inline message" value={props.content.text}></input>
      <input className="form-control mb-2" name={`interaction.${props.interactionKey}.content.url`} onChange={(e) => {onChange("url", e)}} placeholder="Link - the url" value={props.content.url}></input>
      <input className="form-control mb-2" name={`interaction.${props.interactionKey}.content.title`} onChange={(e) => {onChange("title", e)}} placeholder="Title of the Link" value={props.content.title}></input>
      <input className="form-control mb-2" name={`interaction.${props.interactionKey}.content.description`} onChange={(e) => {onChange("description", e)}} placeholder="Description of the Link" value={props.content.description}></input>
      <input className="form-control mb-2" type="file" onChange={handleImageChange} />
    </div>
  )
} 

const VideoInteraction = (props) => {
  const {organizations, currentOrganization } = useContext(AuthContext)
  const onChange = (key, e) => {
    props.handleChange({...props.content, [key]: e.target.value})
  }

  const handleImageChange = async (e) => {
    const url = await uploadImage(e.target.files[0], currentOrganization.storageId)
    onChange("thumbnail", {target:{value:url}})
  }

  async function  uploadImage(image, path) {
      try {
        
        const fileRef = ref( storage, `/organizations/${path}/` + image.name);
        const uploadTask = await uploadBytesResumable(fileRef, image);

        const downloadURL = await getDownloadURL(uploadTask.ref)

        return downloadURL

      } catch (error) {
        console.log("ERR ===", error);
        alert("Image uploading failed!");
      }
    }

  return (
    <div>
      <input className="form-control mb-2" name={`interaction.${props.interactionKey}.content.text`} onChange={(e) => {onChange("text", e)}} placeholder="Chat content text" value={props.content.text}></input>
      <input className="form-control mb-2" name={`interaction.${props.interactionKey}.content.url`} onChange={(e) => {onChange("url", e)}} placeholder="Chat content URL" value={props.content.url}></input>
      <input className="form-control mb-2" name={`interaction.${props.interactionKey}.content.title`} onChange={(e) => {onChange("title", e)}} placeholder="Chat content text" value={props.content.title}></input>
      <input className="form-control mb-2" name={`interaction.${props.interactionKey}.content.description`} onChange={(e) => {onChange("description", e)}} placeholder="Chat content text" value={props.content.description}></input>
      <input className="form-control mb-2" type="file" onChange={handleImageChange} />
    </div>
  )
}


const OptionsInteraction = (props) => {
  const {organizations, currentOrganization } = useContext(AuthContext)
  const onChange = (key, e) => {
    props.handleChange({...props.content, [key]: e.target.value})
  }

  const addOption = () => {
    props.handleChange({ ...props.content, options: [...props.content.options, {trigger: "", next: "default", nextType: "TRIGGER_INTERACTION"}]})
  }

  const onOptionChange = (key, e, i) => {
    let newOption = {...props.content.options[i], [key]: e.target.value}
    props.handleChange({ ...props.content, options: props.content.options.map((opt, x) => x==i?  newOption:opt )})
  }



  return (
    <div>
      <textarea className="form-control" name={`interaction.${props.interactionKey}.content.text`} onChange={(e) => {onChange("text", e)}} placeholder="Chat content text" value={props.content.text}></textarea>
      <ul className="d-flex flex-column my-3 list-unstyled">
      {props.content.options.map((opt, i) => {
        return (
          <li className="d-flex flex-row align-items-center mb-2" key={i}>
          <span className="me-2 fw-bold"><small>{i}</small></span>
          <input onChange={(e) => onOptionChange('trigger', e, i)} className="form-control"  name={`interaction.content.options${i}.trigger`} value={opt.trigger}/>
           <select className="form-select ms-3" value={opt.next} onChange={(e) => onOptionChange('next', e, i)} >
            <option value="default"><bold>Next:</bold> Default</option>
            {props.nextOptions.filter(el => el.order == props.order? false: true).map(el => {return <option value={el.id}>Interaction #{el.order}</option>})}
          </select>
          </li>)
      })}
      
      </ul>
      <button type="button" onClick={addOption} className="btn">Add Option</button>
    </div>
  )
} 

const InteractionOption = (props) => {
  return (<div>
      {props.option.trigger}
    </div>)
}

export default Interaction