
const logoUrl = "https://firebasestorage.googleapis.com/v0/b/galapagos-waddle.appspot.com/o/assets%2Funify_logo.png?alt=media&token=550c0953-7ef7-4656-bff0-adff998c1b6e"


const Loading = () => {

	return (
		<main className="loading h-100 d-flex flex-row justify-content-center align-items-center flex-grow-1">
			<div>
				<div className="dot-flashing"/>
				<img className="loading-logo-image" src={logoUrl}/>
			</div>
		</main>
		)


}

export default Loading;