import { Link , useLocation} from "react-router-dom"
import {useContext,useEffect} from 'react'
import { Speedometer,ChatRightTextFill, UiChecks, FolderFill, GearFill, PeopleFill, PersonFill, BoxArrowUpRight } from 'react-bootstrap-icons';

import { AuthContext } from "../contexts/AuthContext";


const logoUrl = "https://firebasestorage.googleapis.com/v0/b/galapagos-waddle.appspot.com/o/assets%2Funify_logo.png?alt=media&token=550c0953-7ef7-4656-bff0-adff998c1b6e"


// Component
const SideBar = (props) => {
	const { organizations, currentOrganization, setCurrentOrganization, user, signOut } = useContext(AuthContext)

	const location = useLocation()

	const selectedOrganizationChange = (e) => {
		organizations.map((o, i) => {
			if(o.id == e.target.value)
				setCurrentOrganization(o)
		})
	}

	
	
	return (
	<div className="wd-sidebar-wrapper">
		<div className="fixed-top wd-sidebar d-flex flex-column">
		<div className="nav-logo-wrapper">
			<h2 className="nav-logo-name"><img className="nav-logo-image" src={logoUrl}/><span>Unichats</span></h2>
		</div>
		{currentOrganization && (
		<section className="nav-organization-selector" onChange={selectedOrganizationChange}>
			<select defaultValue={currentOrganization.id}>
			{organizations.map((o,i) => {
				return (<option key={i} value={o.id} style={{backgroundImage: `url("${o.data().logo}")`}}>{o.data().name} </option>)
			})}
			</select>
		</section>)}
	    <ul class="nav nav-pills d-flex flex-column flex-grow-1">

	    	<li class="nav-item">
		    <Link className={`nav-link ${location.pathname.endsWith("unifyapply.com/")? "active": ""}`} to="/"><Speedometer className="nav-icon"/><span className="content">Dashboard</span></Link>
		  </li>
	    	<li className="nav-title">
	    	Chat Flows
	    	</li>
		  <li class="nav-item">
		    <Link to="/dashboard/flows" className={`nav-link ${location.pathname.includes("flows")? "active": ""}`} aria-current="page" href="#"><ChatRightTextFill className="nav-icon"/><span className="content">Flows</span></Link>
		  </li>
		  <li class="nav-item">
		     <Link to="/dashboard/forms" className={`nav-link ${location.pathname.includes("forms")? "active": ""}`} aria-current="page" href="#"><UiChecks className="nav-icon"/><span className="content">Forms</span></Link>
		  </li>
		  {currentOrganization && (
			  <li class="nav-item">
			     <Link to={`https://widget.unifyapply.com/?key=${currentOrganization.id}&preview=true`} target="_blank" rel="noopener noreferrer" className={`nav-link`} aria-current="page" href="#"><BoxArrowUpRight className="nav-icon"/><span className="content">Preview</span></Link>
			  </li>)
			}
		  {/**<li className="nav-title">
	    	Leads
	    	</li>
		  <li class="nav-item">
		    <a class="nav-link" href="#"><PeopleFill className="nav-icon"/><span className="content">All Leads</span></a>
		  </li>**/}
		  {/**<li class="nav-item">
		    <a class="nav-link disabled"><span className="content">Disabled</span></a>
		  </li>**/}
		  <li className="nav-title">
	    	Settings
	    	</li>
		  <li class="nav-item">
		    <Link to="/dashboard/settings" className={`nav-link ${location.pathname.includes("settings")? "active": ""}`} href="#"><GearFill className="nav-icon"/><span className="content">Configuration</span></Link>
		  </li>
		  {/**<li class="nav-item">
		    <a class="nav-link" href="#"><FolderFill className="nav-icon"/><span className="content">Files</span></a>
		  </li>**/}
		</ul>
		{ user && (
		<section className="nav-user d-flex flex-row">
			<div className="nav-user-icon"><PersonFill/></div>
			<div className="nav-user-content flex-grow-1 d-flex flex-column justify-content-center ps-3">
				<span>{user.displayName}</span>
				<a href="#" onClick={(e) => {e.preventDefault(); signOut();}}>Log out</a>
			</div>
		</section>
		)}
		</div>
    </div>

		)
}

export default SideBar;