import { doc, getDoc, getDocs, addDoc, deleteDoc, setDoc, collection, query, where } from "@firebase/firestore";
import { firestore } from "./firebase";


const getUserOrganizations = async (uid) => {
	try {
		const q = query(collection(firestore, "organizations"), where("users", "array-contains", uid));
		const docs = await getDocs(q);
		if (docs.docs.length === 0) {
			return []
		}
		else {
			return docs.docs
		}
	}
	catch(err) {
		console.error(err);
    	//alert(err.message);
    	return []
	}
}

const createUserOrganization = async (organization, user) => {

	organization.users = [user.uid];

	try {
		const doc = await addDoc(collection(firestore, "organizations"), organization);
		return true
	}
	catch(err) {
		console.log(err)
		return false
	}


}

const createOrganizationFlow = async (flow, organization) => {


	try {
		const doc = await addDoc(collection(firestore, "organizations", organization.id, "flows"), flow);
		return true
	}
	catch(err) {
		console.log(err)
		return false
	}

}

const getOrganizationFlows = async (organization) => {

	try {
		const docs = await getDocs(collection(firestore, "organizations", organization.id, "flows"));
		if (docs.docs.length === 0) {

			return []
		}
		else {
			return docs.docs
		}
	}
	catch(err) {
		console.error(err);
    	//alert(err.message);
    	return []
	}
}

const getOrganizationFlow = async (organization, id) => {

		try {
			const flow = await getDoc(doc(firestore, "organizations", organization.id, "flows", id));
			if (!flow.exists()) {

				return {}
			}
			else {
				return {... flow.data(), id: flow.id}
			}
		}
		catch(err) {
			console.error(err);
	    	//alert(err.message);
	    	return {}
	}
}

const updateOrganizationFlow = async (organization, flow) => {

	try {
		let auxFlow = { ...flow };
		delete auxFlow.id
			const result = await setDoc(doc(firestore, "organizations", organization.id, "flows", flow.id), auxFlow);
			return result;
		}
		catch(err) {
			console.error(err);
	    	//alert(err.message);
	    	return {}
	}
}

const deleteOrganizationFlow = async (organization, id) => {
	try {
		const result = await deleteDoc(doc(firestore, "organizations", organization.id, "flows", id));
		return result;
	}
		catch(err) {
			console.error(err);
	    	//alert(err.message);
	    	return {}
	}
}

const getOrganizationFormSubmissions = async (organization, id) => {
	try {
		const q = query(collection(firestore, "organizations", organization.id, "forms_submissions"), where("formId", "==", id));
		const docs = await getDocs(q);
		if (docs.docs.length === 0) {
			return []
		}
		else {
			return docs.docs
		}
	}
	catch(err) {
		console.error(err);
    	//alert(err.message);
    	return []
	}
}

export {
	getUserOrganizations,
	createUserOrganization,
	createOrganizationFlow,
	getOrganizationFlows,
	getOrganizationFlow,
	updateOrganizationFlow,
	deleteOrganizationFlow,
	getOrganizationFormSubmissions
}

