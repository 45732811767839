import { createContext, useContext, useEffect, useState } from 'react'
import {useAuth} from '../services/firebase';
import { useAuthState, useSignOut } from "react-firebase-hooks/auth";
import { getUserOrganizations } from '../services/firestoreHandler';

import Loading from "../components/Loading"

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [user, loading, error] = useAuthState(useAuth);
   const [signOut] = useSignOut(useAuth);

  const [organizations, setOrganizations] = useState(null)
  const [currentOrganization, setCurrentOrganization] = useState(null)

  useEffect(() => {
 	const getOrganizations = async (uid) => {
 		const docs = await getUserOrganizations(uid);
 
 		setOrganizations(docs)
 	}
 	if(user)
	 	getOrganizations(user.uid);
  }, [user]);

if(!loading)
  return (
    <AuthContext.Provider value={{ user, loading, organizations, setOrganizations, currentOrganization, setCurrentOrganization, signOut }}>{children}</AuthContext.Provider>
  );
  else
  	return (<Loading/>)
};

export {
	AuthProvider,
	AuthContext
}