import { useEffect, useContext, useState, useRef } from "react"
import { AuthContext } from "../contexts/AuthContext"
import { createOrganizationFlow, getOrganizationFlows, getOrganizationFlow, updateOrganizationFlow, deleteOrganizationFlow } from '../services/firestoreHandler'
import {v4 as uuidv4} from 'uuid';
import Interaction  from "../components/Interactions"
import Loading  from "../components/Loading"
import { Link, useNavigate, useParams } from "react-router-dom";
import { ChatSquare, Pencil, Trash, Plus, LightningFill, GearFill } from 'react-bootstrap-icons';
import toast from 'react-hot-toast';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';


const _flows_columns = [
  {
    name: "order",
    header: "#",
    defaultWidth: 55
  },
  {
    name: "trigger",
    header: "Name",
    minWidth: 200,
    defaultFlex: 2,
    enableColumnFilterContextMenu: true,
    render: ({value}) => <span className="text-dark">{value}</span>
  },
  {
    name: "category",
    header: "Category",
    defaultFlex: 1,
    minWidth: 120,
    render: ({value}) => <span>{value}</span>
  },
  {
    name: "interactions",
    header: "Interactions",
    defaultWidth: 120,
    minWidth: 100,
    render: ({value}) => {
      return (<span className="d-flex flex-row align-items-center ua--badge">{value.length}</span>)
    }
  },
  {
    name: "actions",
    header: "",
    defaultWidth: 120,
    render: ({data}) => <div className="d-flex flex-row"> <Link to={`${data.id}`} className="btn btn-sm btn--ua-backoffice btn--ua-light me-2 text-primary"><Pencil className="bi bi-pencil me-2"/> <small>Edit</small></Link></div>
  }
]

const _flows_value = [
  { name: 'trigger', operator: 'contains', type: 'string', value: '' },
 ]


function FlowsPage() {
  const { user, organizations, currentOrganization } = useContext(AuthContext)
  const [flows, setFlows] = useState([])
  const [loadingData, setLoadingData] = useState(true);
  const [dataTableRef, setDataTableRef] = useState(null);

  useEffect(() => {
    if(currentOrganization)
      getFlows(currentOrganization);
  },[currentOrganization])


  const getFlows = async (organization) => {
    const docs = await getOrganizationFlows(organization);
    const _flows = []
    docs.filter(el => el.data().type == "default").map((flow, i) => {
      _flows.push({
        id: flow.id,
        order: i+1,
        trigger: flow.data().trigger,
        category: flow.data().category,
        interactions: flow.data().interactions
      })
    });

    setFlows(_flows);
    setLoadingData(false);
  
  }

  const deleteFlow = async (id) => {
    await deleteOrganizationFlow(currentOrganization, id);
    setFlows(flows.filter((doc) => { return doc.id !== id}));
  }


  return (
      <main className="ua-main__landings flex-grow-1 d-flex flex-column h-100 overflow-hidden">
        <header className="ua-main__header px-3 px-md-5 pt-5 pb-4 d-flex flex-row justify-content-between align-items-center">
          <h1 className="m-0">Flows</h1>
          <div className="d-flex flex-row">
            <Link to="create" className={`btn btn-dark btn--ua-backoffice ${loadingData?"disabled":null}`}>New Flow</Link>
          </div>
        </header>
        <div className="ua-main__content px-3 px-md-5 flex-grow-1 pb-3 overflow-auto d-flex">
        {loadingData? <Loading/>:(
        <ReactDataGrid
          onReady={setDataTableRef}
          className="ua-datagrid rounded-3 animate__animated animate__fadeIn"
            idProperty="id"
            columns={_flows_columns}
            dataSource={flows}
            pagination
            defaultLimit={15}
            rowHeight={null}
            minRowHeight={50}
            defaultFilterValue={_flows_value}
        />)}
      </div>
      </main>
  );
}

function UpdateFlowPage(props) {
  const { user, organizations, currentOrganization } = useContext(AuthContext)
  const [flow, setFlow] = useState({})
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {

    if(currentOrganization)
      getFlow(params.flowId)
  }, [currentOrganization])

  const getFlow = async (id) => {

    const doc = await getOrganizationFlow( currentOrganization, id)
    setFlow(doc)
  }

  const handleSave = async (e, interactions) => {
    e.preventDefault();


    if(e.target.trigger.value == "" || !e.target.trigger.value) {
      toast.error('Trigger value cannot be empty')
      return;
    }
    if(!e.target.category) 
      e.target.category = {value: ""}
    if(!e.target.defaultTrigger) 
      e.target.defaultTrigger = {value: false}

    const flow = {
      id: params.flowId,
      trigger: e.target.trigger.value,
      category: e.target.category.value,
      type: "default",
      defaultTrigger: e.target.defaultTrigger.value=="false" || !e.target.defaultTrigger.value? false:true,
      interactions: interactions
    }
    const doc = await updateOrganizationFlow(currentOrganization, flow)
    navigate("/dashboard/flows")
  }

  const handleCancel = () => {
    navigate("/dashboard/flows")
  }

  const breadcrumbComponent = () => {
    return (<ol className="breadcrumb">
              <li className="breadcrumb-item" aria-current="page"><Link to="/dashboard/flows">Flows</Link></li>
              <li class="breadcrumb-item active" aria-current="page">{flow.trigger}</li>
            </ol>)
  }

  if(flow.id)
    return <FlowForm breadcrumb={breadcrumbComponent} flow={flow} handleSubmit={handleSave} handleCancel={handleCancel}/>
  else
    return null

}


function CreateFlowPage() {
  const { user, organizations, currentOrganization } = useContext(AuthContext)
  const navigate = useNavigate();

   const handleSave = async (e, interactions) => {
    // Prevent the browser from reloading the page
    e.preventDefault();

    if(e.target.trigger.value == "" || !e.target.trigger.value) {
      toast.error('Trigger value cannot be empty')
      return;
    }
    if(!e.target.category) 
      e.target.category = {value: ""}
    if(!e.target.defaultTrigger) 
      e.target.defaultTrigger = {value: false}

    const flow = {
      trigger: e.target.trigger.value,
      category: e.target.category.value,
      type: "default",
      defaultTrigger: e.target.defaultTrigger.value=="false" || !e.target.defaultTrigger.value? false:true,
      interactions: interactions
    }
    const doc = await createOrganizationFlow(flow, currentOrganization)
    navigate("/dashboard/flows")
  }

  const handleCancel = () => {
    navigate("/dashboard/flows")
  }

  const breadcrumbComponent = () => {
    return (<ol className="breadcrumb">
              <li className="breadcrumb-item" aria-current="page"><Link to="/dashboard/flows">Flows</Link></li>
              <li class="breadcrumb-item active" aria-current="page">New</li>
            </ol>)
  }

  return (      
       <FlowForm breadcrumb={breadcrumbComponent} handleSubmit={handleSave} handleCancel={handleCancel}/>
  );
}


function FlowForm(props) {
  const { user, organizations, currentOrganization } = useContext(AuthContext)
  const [ interactions, setInteractions ] = useState([]);
  const [flow, setFlow] = useState({})
  const navigate = useNavigate();
  const form = useRef(null)

  useEffect(() => {
    if(props.flow) {
      setInteractions(props.flow.interactions)
      setFlow(props.flow)
    }
  }, [props.flow])


  const handleFlowChange = (key,e) => {

    if(key == "defaultTrigger")
      if(flow.defaultTrigger=="false" || !flow.defaultTrigger)
        e.target.value = true;
      else
        e.target.value = false;

    setFlow({...flow, [key]: e.target.value})
  }

  const addInteraction = () => {
    const newInteractionId = uuidv4()
    const newInteraction = {id: newInteractionId, type: "text", content: {text: ""}, next: "end", order: interactions.length + 1}
    if(interactions.length > 0) {
      const lastInteraction = interactions[interactions.length - 1]
      lastInteraction.next = newInteractionId
      handleInteractionChange(lastInteraction);
    }
    else {
      newInteraction.first = true;
    }

    setInteractions([...interactions, newInteraction])
  }

  const deleteInteraction = (toDelete) => {
    let auxInteractions = interactions.filter((interaction) => {
      return interaction.id !== toDelete.id;
     });
    auxInteractions = auxInteractions.map((el, i) => {
      return {...el, order: i+1}
    })

    auxInteractions = auxInteractions.map((interaction) => {
      if(interaction.next == toDelete.id)
        interaction.next = toDelete.next;
      return interaction
    })

     setInteractions(auxInteractions)
  } 

  const handleInteractionChange = (newInteraction) => {

    setInteractions(interactions.map((interaction,i) => {
      if(interaction.id == newInteraction.id){
        if(interaction.type != newInteraction.type)
          if(newInteraction.type == "text_with_options")
            newInteraction.content.options = []
        return newInteraction
      }
      else
        return interaction
    }))
  }

    const renderInteraction = (interaction, key) => {
      return <Interaction key={key} interactionKey={key} handleChange={handleInteractionChange} interaction={interaction} deleteHandle={deleteInteraction}/>
    }


    const renderInteractions = () => {
       
      
      if(interactions.length == 0)
        return;
      const firstInteraction = interactions.filter((interaction) => {return interaction.first})[0]

      if(!firstInteraction)
        return;
      return renderInteractionsTree(firstInteraction)
    }

  const renderInteractionsTree = (firstInteraction) => {
   

    let next = firstInteraction.id;
    let moreInteractions = true;
    let key = 0;
    const renderResult = [];
    while(moreInteractions) {
      let nextInteraction = interactions.filter((interaction) => {return interaction.id == next})[0];
      renderResult.push(renderInteraction(nextInteraction, key));

      if(nextInteraction.type == "text_with_options") {
        let lanes = []
        nextInteraction.content.options.forEach((opt, i) => {
          lanes.push(<div className="">option {i}</div>)
        })
        renderResult.push(<div className="d-flex flex-row">{lanes.map((el) => {return el})}</div>)
      }

      key++;
      next = nextInteraction.next;
      if(next == "end")
        moreInteractions = false;

    }

    return (
      <div className="d-flex flex-column align-items-center interactions-builder-wrapper">
        {renderResult.map((res) => {return res})}
      </div>
      )


  }



  return (
        <main className="d-flex flex-grow-1 flex-column p-relative">
        <header className="main-header d-flex flex-row p-2 px-4 align-items-center sticky-top bg-white">
          <nav aria-label="breadcrumb">
            {props.breadcrumb()}
          </nav>
          <section className="main-header-actions d-flex flex-grow-1 justify-content-end">
           <button onClick={props.handleCancel} className="btn btn-light me-4" type="button" >Return without saving</button>
            <button className="btn btn-primary" form="flow-form" type="submit" >Save</button>
          </section>
        </header>

        <form id="flow-form" ref={form} onSubmit={(e) => {props.handleSubmit(e,interactions)}} className="m-3 p-4 pt-5 bg-dotted rounded-3 flex-grow-1 d-flex flex-column">
          
        
          <section className="h-100 d-flex flex-column align-items-center">
            <div className="card card-trigger">
              <span className="label">Start</span>
              <div className="card-header bg-white d-flex flex-row align-items-center"> 
                <div className="card-header-icon-wrapper me-2"><LightningFill/></div>
                <small className="fw-bold text-uppercase">Trigger</small>
                <a className="btn justify-self-end ms-auto btn-rotate"> <GearFill/> </a>
              </div>
              <div className="card-body">
                <div className="mb-3">
                  <label>
                  Trigger: </label> 

                  <input className="form-control" name="trigger" onChange={(e) => handleFlowChange("trigger", e)} value={flow.trigger} placeholder="What will trigger the following flow? Ex: Why study here?" />
                </div>
                
                  

              </div>
              <section className="card-footer bg-white">

               <div className="mb-3">
                  <label>
                  Category: 
                  </label><input className="form-control" name="category" onChange={(e) => handleFlowChange("category", e)}  value={flow.category} placeholder="Which category?" />
                  </div>
                  <div className="mb-3">
                  <label for="checkbox-trigger">
                  Default Prompt 
                  </label> <input name="defaultTrigger" type="checkbox" className="" checked={flow.defaultTrigger} onChange={(e) => handleFlowChange("defaultTrigger", e)}  autocomplete="off" id="checkbox-trigger"/>
                  </div>

              {props.isForm && (
                <div>
                  <label>Hubspot Integration</label>
                  <input className="form-control" name="hubspotPortalId" onChange={(e) => handleFlowChange("hubspotPortalId", e)} value={flow.hubspotPortalId} placeholder="Portal Id" />
                 <input className="form-control" name="hubspotFormId" onChange={(e) => handleFlowChange("hubspotFormId", e)} value={flow.hubspotFormId} placeholder="Form Id" />
                </div> 
                )}
              </section>
            </div>
            <div className="connector" />
             {/**renderInteractions()**/}
            <div className="d-flex flex-column align-items-center interactions-builder-wrapper">
              {interactions.sort((a,b) => {return a.order - b.order}).map((interaction, key) => {
                return (<div className="w-100 d-flex flex-column align-items-center"><Interaction isForm={props.isForm} key={key} nextOptions={interactions.map(el => {return {id: el.id, order: el.order, type: "interaction"}})} interactionKey={key} handleChange={handleInteractionChange} interaction={interaction} deleteHandle={deleteInteraction}/><div className="connector" /></div>)
              })}
               </div>
               
             <div className="w-100 text-center">
            <button type="button" className="btn btn-primary" onClick={addInteraction}>Add interaction</button>

            </div>
            <div className="connector" />
            <div className="w-100 text-center">
            <button type="button" className="btn disabled btn-danger">End</button>
            </div>
          </section>
        </form>
        </main>
  );


}

export {
  FlowsPage,
  CreateFlowPage,
  UpdateFlowPage,
  FlowForm
}