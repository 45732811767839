import { useContext, useEffect } from 'react'
import { useNavigate, Outlet } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext"
import SideBar from './SideBar'
import toast, { Toaster } from 'react-hot-toast';
import '@inovua/reactdatagrid-community/index.css'


const ProtectedLayout = () => {
  //const {user} = useContext(AuthProvider); 
  var { user, loading, organizations, currentOrganization } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
   
  }, [user, loading]);

  return (
    <div className="d-flex h-100 w-100 wd-console-wrapper flex-row">
    <Toaster/>
    <SideBar/>
    {!loading && user &&
    
    <main className="flex-grow-1 d-flex flex-column">
    <div></div>

    <div className="flex-grow-1 main-content d-flex">
      <Outlet />
      </div>
      </main>
    }
    </div>
  )
};

export default ProtectedLayout;