import {v4 as uuidv4} from 'uuid';
import { useContext, useState, useEffect } from 'react'
import { storage } from '../services/firebase'
import { createUserOrganization, getUserOrganizations } from '../services/firestoreHandler'
import { useNavigate} from "react-router-dom";

import { AuthContext } from "../contexts/AuthContext"
import {
    ref,
    uploadBytesResumable,
    getDownloadURL 
} from "firebase/storage";


function UpdateOrganizationPage() {

     const { user, organizations, currentOrganization, setCurrentOrganization  } = useContext(AuthContext)

  const [organization, setOrganization] = useState([])
const navigate = useNavigate();

  const [logoFile, setLogoFile] = useState(null);
  const [assistantAvatarFile, setAssistantAvatarFile] = useState(null);

  const [logoPreview, setLogoPreview] = useState();
  const [avatarPreview, setAvatarPreview] = useState();

  const [name, setName] = useState("")
  const [assistantName, setAssistantName] = useState("")
  const [assistantGreeting, setAssistantGreeting] = useState("")



  useEffect(() => {

    if(currentOrganization){
      setOrganization(currentOrganization);
      setName(currentOrganization.data().name)
      setLogoPreview(currentOrganization.data().logo)
      setAssistantName(currentOrganization.data().assistant.name)
      setAssistantGreeting(currentOrganization.data().assistant.greeting)
      setAvatarPreview(currentOrganization.data().assistant.avatar)
    }
  },[,currentOrganization])

  
  

  function handleImageChange(e) {
        if(e.target.name.includes("assistant.avatar")) {
           setAvatarPreview(URL.createObjectURL(e.target.files[0]));
           setAssistantAvatarFile(e.target.files[0])
         }
        else {
          setLogoPreview(URL.createObjectURL(e.target.files[0]));
          setLogoFile(e.target.files[0])
        }
  }

  async function  uploadImage(image, path) {
      try {
        
        const fileRef = ref( storage, `/organizations/${path}/` + image.name);
        const uploadTask = await uploadBytesResumable(fileRef, image);

        const downloadURL = await getDownloadURL(uploadTask.ref)

        return downloadURL

      } catch (error) {
        console.log("ERR ===", error);
        alert("Image uploading failed!");
      }
    }

  const handleSubmit = async (e) => {
    // Prevent the browser from reloading the page
    e.preventDefault();

    //WIP manage validations
    if(e.target.name.value == "")
      return


    const logoUrl = !logoFile? await uploadImage(logoFile, organization.storageId): organization.logo;
    const avatarUrl = !assistantAvatarFile? await uploadImage(assistantAvatarFile, organization.storageId): organization.assistant.avatar;


    const _organization = {}
    _organization.storageId = organization.id;
    _organization.name = name;
    _organization.logo = logoUrl;
    _organization.assistant = {
      name: assistantName,
      greeting: assistantGreeting,
      avatar: avatarUrl
    }

    //const result = await createUserOrganization(organization, user)
    
    


  }
  return (
      <main className="flex-grow-1 overflow-auto">
        <h1>{name}</h1>

        <form onSubmit={handleSubmit}>
          <label>
            Name: <input name="name" placeholder="Your Organization Name" value={name}  />
          </label>


          <hr />
            <label>
            Assistant Name: <input name="assistant.name" placeholder="Alicia" value={assistantName}/>
          </label>
          <label>
            Assistant Greeting: <input name="assistant.greeting" defaultValue="Welcome, How can I help you today?" value={assistantGreeting}/>
          </label>
          <label> Assistant Avatar
          <input name="assistant.avatar" type="file" onChange={handleImageChange} />
            <img src={avatarPreview} />
            </label>
          <hr />
          <label> Organization Logo
          <input type="file" onChange={handleImageChange} />
            <img src={logoPreview} />
            </label>

            <button type="submit">Submit</button>
        </form>
      </main>
  );
}

export { UpdateOrganizationPage}