

import { Routes, Route, useNavigate } from "react-router-dom";

import ProtectedLayout  from './components/ProtectedLayout';
import LoginPage from "./pages/Login";
import HomePage from "./pages/Home";

import { FlowsPage, CreateFlowPage, UpdateFlowPage } from "./pages/Flows";
import { FormsPage, CreateFormPage, UpdateFormPage, FormSubmissionsPage } from "./pages/Forms";
import {  UpdateOrganizationPage } from "./pages/Organizations"
import CreateOrganizationPage from "./pages/CreateOrganization";
import { useContext, useEffect } from 'react'

import { AuthContext } from "./contexts/AuthContext"





import 'bootstrap/dist/css/bootstrap.css';
import "./App.scss"

function App() {
  const { organizations, currentOrganization, setCurrentOrganization } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => { 
    if(organizations)
      if(organizations.length == 0) {
        return navigate('/organizations/create')
      }
      else
        if(!currentOrganization)
          setCurrentOrganization(organizations[0])


  }, [organizations]);

  return (
    
     <Routes>
      
        <Route path="/login" element={<LoginPage />} />
        <Route  element={<ProtectedLayout />}>
        <Route path="organizations/create" element={<CreateOrganizationPage  />} />
         <Route path="/" element={<HomePage />} />
          <Route path="dashboard/flows" element={<FlowsPage/>} />
          <Route path="dashboard/flows/create" element={<CreateFlowPage/>}/>
          <Route path="dashboard/flows/:flowId" element={<UpdateFlowPage/>}/>
          <Route path="dashboard/forms" element={<FormsPage/>} />
          <Route path="dashboard/forms/create" element={<CreateFormPage/>}/>
          <Route path="dashboard/forms/:formId" element={<UpdateFormPage/>}/>
          <Route path="dashboard/forms/:formId/submissions" element={<FormSubmissionsPage/>}/>
          <Route path="dashboard/settings" element={<UpdateOrganizationPage/>}/>
        </Route>

      </Routes>
    
  );
}

export default App;
