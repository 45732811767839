import {v4 as uuidv4} from 'uuid';
import { useContext, useState } from 'react'
import { storage } from '../services/firebase'
import { createUserOrganization, getUserOrganizations } from '../services/firestoreHandler'
import { useNavigate} from "react-router-dom";

import { AuthContext } from "../contexts/AuthContext"
import {
    ref,
    uploadBytesResumable,
    getDownloadURL 
} from "firebase/storage";


function CreateOrganizationPage() {

  const navigate = useNavigate();
  const { user, organizations, setOrganizations } = useContext(AuthContext)

  const [logoFile, setLogoFile] = useState();
  const [assistantAvatarFile, setAssistantAvatarFile] = useState();

  const [logoPreview, setLogoPreview] = useState();
  const [avatarPreview, setAvatarPreview] = useState();
  

  function handleImageChange(e) {
        if(e.target.name.includes("assistant.avatar")) {
           setAvatarPreview(URL.createObjectURL(e.target.files[0]));
           setAssistantAvatarFile(e.target.files[0])
         }
        else {
          setLogoPreview(URL.createObjectURL(e.target.files[0]));
          setLogoFile(e.target.files[0])
        }
  }

  async function  uploadImage(image, path) {
      try {
        
        const fileRef = ref( storage, `/organizations/${path}/` + image.name);
        const uploadTask = await uploadBytesResumable(fileRef, image);

        const downloadURL = await getDownloadURL(uploadTask.ref)

        return downloadURL

      } catch (error) {
        console.log("ERR ===", error);
        alert("Image uploading failed!");
      }
    }

  const handleSubmit = async (e) => {
    // Prevent the browser from reloading the page
    e.preventDefault();

    //WIP manage validations
    if(e.target.name.value == "")
      return

    const id = uuidv4();

    const logoUrl = await uploadImage(logoFile, id);
    const avatarUrl = await uploadImage(assistantAvatarFile, id);


    const organization = {}
    organization.storageId = id;
    organization.name = e.target.name.value;
    organization.logo = logoUrl;
    organization.assistant = {
      name: e.target["assistant.name"].value,
      greeting: e.target["assistant.greeting"].value,
      avatar: avatarUrl
    }

    const result = await createUserOrganization(organization, user)
    if(result) {
      const docs = await getUserOrganizations(user.uid);
      setOrganizations(docs)
      navigate("/dashboard")
    }
    


  }
  return (
      <main>
        <h1>New Organization</h1>

        <form onSubmit={handleSubmit}>
          <label>
            Name: <input name="name" placeholder="Your Organization Name" />
          </label>


          <hr />
            <label>
            Assistant Name: <input name="assistant.name" placeholder="Alicia" />
          </label>
          <label>
            Assistant Greeting: <input name="assistant.greeting" defaultValue="Welcome, How can I help you today?" />
          </label>
          <label> Assistant Avatar
          <input name="assistant.avatar" type="file" onChange={handleImageChange} />
            <img src={avatarPreview} />
            </label>
          <hr />
          <label> Organization Logo
          <input type="file" onChange={handleImageChange} />
            <img src={logoPreview} />
            </label>

            <button type="submit">Submit</button>
        </form>
      </main>
  );
}

export default CreateOrganizationPage;