import { useEffect, useContext, useState } from "react"
import { AuthContext } from "../contexts/AuthContext"
import { createOrganizationFlow, getOrganizationFlows, getOrganizationFlow, updateOrganizationFlow, deleteOrganizationFlow, getOrganizationFormSubmissions } from '../services/firestoreHandler'
import {v4 as uuidv4} from 'uuid';
import Interaction  from "../components/Interactions"
import Loading  from "../components/Loading"
import { Link, useNavigate, useParams } from "react-router-dom";
import { ChatSquare, Pencil, Trash, Plus } from 'react-bootstrap-icons';
import { FlowForm } from './Flows'
import toast from 'react-hot-toast';

import { CSVLink, CSVDownload } from "react-csv";

import ReactDataGrid from '@inovua/reactdatagrid-community';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';



const _flows_columns = [
  {
    name: "order",
    header: "#",
    defaultWidth: 55
  },
  {
    name: "trigger",
    header: "Name",
    minWidth: 200,
    defaultFlex: 2,
    enableColumnFilterContextMenu: true,
    render: ({value}) => <span className="text-dark">{value}</span>
  },
  {
    name: "category",
    header: "Category",
    defaultFlex: 1,
    minWidth: 120,
    render: ({value}) => <span>{value}</span>
  },
  {
    name: "interactions",
    header: "Questions",
    defaultWidth: 100,
    minWidth: 100,
    render: ({value}) => {
      return (<span className="d-flex flex-row align-items-center ua--badge">{value.length}</span>)
    }
  },

  {
    name: "actions",
    header: "",
    defaultWidth: 200,
    render: ({data}) => <div className="d-flex flex-row"> <Link to={`${data.id}/submissions`} className="btn btn-sm btn--ua-backoffice btn--ua-light me-2 text-primary"> <small>See Leads</small></Link> <Link to={`${data.id}`} className="btn btn-sm btn--ua-backoffice btn--ua-light me-2 text-primary"><Pencil className="bi bi-pencil me-2"/> <small>Edit</small></Link></div>
  }
]

const _flows_value = [
  { name: 'trigger', operator: 'contains', type: 'string', value: '' },
 ]


function FormsPage() {
  const { user, organizations, currentOrganization, setCurrentOrganization  } = useContext(AuthContext)
  const [flows, setFlows] = useState([])
  const [loadingData, setLoadingData] = useState(true);
  const [dataTableRef, setDataTableRef] = useState(null);


  useEffect(() => {
    
    if(currentOrganization)
      getFlows(currentOrganization);
  },[,currentOrganization])

  /*useEffect(() => {
    const getFlows = async (organization) => {
      const docs = await getOrganizationFlows(organization)
      setFlows(docs)
    }
    if(organizations)
      getFlows(currentOrganization)
  },[])*/

  const getFlows = async (organization) => {
    const docs = await getOrganizationFlows(organization);
    const _flows = []
    docs.filter(el => el.data().type == "form").map((flow, i) => {
      _flows.push({
        id: flow.id,
        order: i+1,
        trigger: flow.data().trigger,
        category: flow.data().category,
        interactions: flow.data().interactions
      })
    });

    setFlows(_flows);
    setLoadingData(false);
  
  }

  const deleteFlow = async (id) => {
    await deleteOrganizationFlow(currentOrganization, id);
    setFlows(flows.filter((doc) => { return doc.id !== id}));
  }


  return (
      <main className="ua-main__landings flex-grow-1 d-flex flex-column h-100 overflow-hidden">
        <header className="ua-main__header px-3 px-md-5 pt-5 pb-4 d-flex flex-row justify-content-between align-items-center">
          <h1 className="m-0">Forms</h1>
          <div className="d-flex flex-row">
            <Link to="create" className={`btn btn-dark btn--ua-backoffice ${loadingData?"disabled":null}`}>New Form</Link>
          </div>
        </header>
        <div className="ua-main__content px-3 px-md-5 flex-grow-1 pb-3 overflow-auto d-flex">
        {loadingData? <Loading/>:(
        <ReactDataGrid
          onReady={setDataTableRef}
          className="ua-datagrid rounded-3 animate__animated animate__fadeIn"
            idProperty="id"
            columns={_flows_columns}
            dataSource={flows}
            pagination
            defaultLimit={15}
            rowHeight={null}
            minRowHeight={50}
            defaultFilterValue={_flows_value}
        />)}
      </div>
      </main>
  );
}

function UpdateFormPage(props) {
  const { user, organizations, currentOrganization  } = useContext(AuthContext)
  const [flow, setFlow] = useState({})
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {

    if(currentOrganization)
      getFlow(params.formId)
  }, [currentOrganization])

  const getFlow = async (id) => {

    const doc = await getOrganizationFlow( currentOrganization, id)
    setFlow(doc)
  }

  const handleSave = async (e, interactions) => {
    e.preventDefault();

     if(e.target.trigger.value == "" || !e.target.trigger.value) {
      toast.error('Trigger value cannot be empty')
      return;
    }
    if(!e.target.category) 
      e.target.category = {value: ""}
    if(!e.target.defaultTrigger) 
      e.target.defaultTrigger = {value: false}

    const flow = {
      id: params.formId,
      trigger: e.target.trigger.value,
      category: e.target.category.value,
      type: "form",
      defaultTrigger: e.target.defaultTrigger.value=="false" || !e.target.defaultTrigger.value? false:true,
      interactions: interactions
    }
    if(e.target.hubspotPortalId.value && e.target.hubspotFormId.value){
      flow.hubspotIntegration = true;
      flow.hubspotPortalId = e.target.hubspotPortalId.value;
      flow.hubspotFormId = e.target.hubspotFormId.value
    }
    const doc = await updateOrganizationFlow(currentOrganization, flow)
    navigate("/dashboard/forms")
  }

  const handleCancel = () => {
    navigate("/dashboard/forms")
  }

   const breadcrumbComponent = () => {
    return (<ol className="breadcrumb">
              <li className="breadcrumb-item" aria-current="page"><Link to="/dashboard/flows">Forms</Link></li>
              <li class="breadcrumb-item active" aria-current="page">{flow.trigger}</li>
            </ol>)
  }


  if(flow.id)
    return <FlowForm isForm={true} breadcrumb={breadcrumbComponent} flow={flow} handleSubmit={handleSave} handleCancel={handleCancel}/>
  else
    return null

}


function CreateFormPage() {
  const { user, organizations, currentOrganization  } = useContext(AuthContext)
  const navigate = useNavigate();

   const handleSave = async (e, interactions) => {
    // Prevent the browser from reloading the page
    e.preventDefault();

    if(e.target.trigger.value == "" || !e.target.trigger.value) {
      toast.error('Trigger value cannot be empty')
      return;
    }
    if(!e.target.category) 
      e.target.category = {value: ""}
    if(!e.target.defaultTrigger) 
      e.target.defaultTrigger = {value: false}

    const flow = {
      trigger: e.target.trigger.value,
      category: e.target.category.value,
      type: "form",
      defaultTrigger: e.target.defaultTrigger.value=="false" || !e.target.defaultTrigger.value? false:true,
      interactions: interactions
    }

    if(e.target.hubspotPortalId.value && e.target.hubspotFormId.value){
      flow.hubspotIntegration = true;
      flow.hubspotPortalId = e.target.hubspotPortalId.value;
      flow.hubspotFormId = e.target.hubspotFormId.value
    }


    const doc = await createOrganizationFlow(flow, currentOrganization)
    navigate("/dashboard/forms")
  }

  const handleCancel = () => {
    navigate("/dashboard/forms")
  }

   const breadcrumbComponent = () => {
    return (<ol className="breadcrumb">
              <li className="breadcrumb-item" aria-current="page"><Link to="/dashboard/flows">Flows</Link></li>
              <li class="breadcrumb-item active" aria-current="page">New</li>
            </ol>)
  }

  return (
       <FlowForm breadcrumb={breadcrumbComponent} isForm={true} handleSubmit={handleSave} handleCancel={handleCancel}/>
  );
}

function FormSubmissionsPage() {
  const { user, organizations, currentOrganization, setCurrentOrganization  } = useContext(AuthContext)
  const [flow, setFlow] = useState({})
  const [submissions, setSubmissions] = useState([])
  const [headers, setHeaders] = useState([])
  const params = useParams();
  const [excelData, setExcelData] = useState([])


  useEffect(() => {

    if(currentOrganization) {
      getFlow(params.formId)
      getSubmissions(params.formId)
    }
  }, [currentOrganization])


  const getFlow = async (id) => {

    const doc = await getOrganizationFlow( currentOrganization, id)

    setFlow(doc)
  }

  const getSubmissions = async (id) => {
    const docs = await getOrganizationFormSubmissions( currentOrganization, id)
    console.log(docs)
    let _headers = [];
    docs.map((doc) => {
      _headers = [... _headers, ...new Set(doc.data().answers.map(item =>  item.id + "-%%%%-" +item.text))];
    })

    _headers = [...new Set(_headers)];
    setHeaders(_headers)


    const _aux = []
    docs.map((doc) => {
      const row = {}
      _headers.map((h) => {
        let answer = doc.data().answers.filter((a) => {if(a.id == h.split("-%%%%-")[0]) return a})[0];
                if(!answer)
                  answer = "";
                else
                  answer = answer.answer
        row[h.split("-%%%%-")[1]] = answer;
      })
      row.submittedAt = doc.data().submittedAt? new Date(doc.data().submittedAt).toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric"}): "";
      _aux.push(row)
    })

    setExcelData(_aux)

    setSubmissions(docs)
  }

  return(
      <main>
        <h1>Submissions</h1>
         <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              {headers.map((h,i) => {
                return <th key={i} scope="col">{h.split("-%%%%-")[1]}</th>
              })}
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
          {submissions.map((sub, i) => {
            return (
              <tr key={i}>
              <th scope="row">{i+1}</th>
              {headers.map((h,x) => {
                let answer = sub.data().answers.filter((a) => {if(a.id == h.split("-%%%%-")[0]) return a})[0];
                if(!answer)
                  answer = "";
                else
                  answer = answer.answer
                return <td key={x}>{answer}</td>
              })}
              <td>{ sub.data().submittedAt? new Date(sub.data().submittedAt).toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric"}): ""}</td>
              </tr>
              )
          })}
          </tbody>
        </table>
        { headers.length > 0?
        (<CSVLink className="btn btn-primary" data={excelData} target="_blank" >Download submissions</CSVLink>):null}
      </main>)
}


export {
  FormsPage,
  CreateFormPage,
  UpdateFormPage,
  FormSubmissionsPage
}