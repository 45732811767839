// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore, doc, getDoc, addDoc, getDocs, collection, query, where } from "@firebase/firestore"



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAB2anr7HylFZprpemGVpyn5azu2k1Q2xU",
  authDomain: "galapagos-waddle.firebaseapp.com",
  projectId: "galapagos-waddle",
  storageBucket: "galapagos-waddle.appspot.com",
  messagingSenderId: "474275324461",
  appId: "1:474275324461:web:8117df45546bc18b6f4ea4",
  measurementId: "G-04MNK6VC01"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);

const provider = new GoogleAuthProvider();
const auth = getAuth();
//signInWithRedirect(auth, provider);


const firestore = getFirestore(app)

const useAuth = auth

export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, provider);
    const user = res.user;
    console.log("correctly signed in")
    const q = query(collection(firestore, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(firestore, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export {
  firestore,
  useAuth,
  storage
}